const links = [
  {
    label: 'Github',
    url: 'https://github.com/ArthurPedroti'
  },
  {
    label: 'Linkedin',
    url: 'https://www.linkedin.com/in/arthurpedroti/'
  }
]

export default links
