const links = [
  {
    label: 'Home',
    url: '/'
  },
  {
    label: 'About me',
    url: '/about/'
  },
  {
    label: 'Livros e Cursos',
    url: '/courses/',
    highlight: true
  }
]

export default links
